import styled, { css } from 'styled-components';
import { Link as UnstyledLink } from '@reach/router';
import theme from '../../theme';
import { normalizeColor } from '../../utils/color';

export const Link = styled(UnstyledLink)`
  ${(props) =>
    props.$isDark
      ? css`
          color: ${normalizeColor(theme.anchor.color, theme, true)};
        `
      : css`
          color: ${normalizeColor(theme.anchor.color, theme, false)};
        `}
  text-decoration: ${theme.anchor.textDecoration};
  font-weight: ${theme.anchor.fontWeight};

  &:hover {
    text-decoration: ${theme.anchor.hover.textDecoration};
  }
`;
