import { Box, Grommet, Layer } from 'grommet';
import { Suspense, lazy, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { TourRoot } from 'react-rtg';
import useBus, { dispatch } from 'use-bus';

import { useLocation } from '@reach/router';

import AppFooter from '../components/app-footer/app-footer';
import AppHeaderSkeleton from '../components/app-header-skeleton';
import AppHeader from '../components/app-header/app-header';
import AppSidebar from '../components/app-sidebar/app-sidebar';
import PrivateRoute from '../components/private-route/private-route';
import Splash from '../components/splash-screen/splash-screen';
import config from '../config';
import { AuthContext, Authenticated, useColorScheme, useSessionStore, useSidebarOpen } from '../hooks';
import theme from '../theme';
import { isSSR } from '../utils';
import GoogleCseDemo from '../views/assistant/google-cse-demo/google-cse-demo';

const SideBar = lazy(() => import('../components/side-bar'));
const Profile = lazy(() => import('../views/assistant/profile/profile'));
const Board = lazy(() => import('../views/assistant/board/board'));
const GridBoard = lazy(() => import('../views/assistant/board-matrix/board-matrix'));
const MyCards = lazy(() => import('../views/assistant/my-cards/my-cards'));
const Page1 = lazy(() => import('../views/admin/page1/page1'));
const Page2 = lazy(() => import('../views/admin/page2/page2'));
const MyConnectors = lazy(() => import('../views/assistant/my-connectors/my-connectors'));
const ChangePassword = lazy(() => import('../views/assistant/change-password/change-password'));

const App = () => {
  const [loading, setLoading] = useState(true);
  const session = useSessionStore((state) => state.session);

  const sidebarOpen = useSidebarOpen((state) => state.sidebarOpen);
  const setSidebarOpen = useSidebarOpen((state) => state.setSidebarOpen);

  const [sidebarConfig, setSidebarConfig] = useState({});

  const location = useLocation();
  const colorScheme = useColorScheme(theme.defaultMode);

  useEffect(() => {
    colorScheme ? document.documentElement.classList.remove('theme-dark') : document.documentElement.classList.add('theme-dark');
  }, [colorScheme]);

  useEffect(() => {
    dispatch('overlays.close');
  }, [location.pathname]);

  useEffect(() => {
    if (session?.authenticated) {
      setLoading(false);
    }
  }, [session?.authenticated]);

  function changeSidebar(event) {
    let sbOpen = !sidebarOpen; // default: toggle current state
    if (event.open != undefined) sbOpen = event.open == true; // set specific state when provided in event
    setSidebarConfig(event);
    setSidebarOpen(sbOpen);
  }

  useBus('sidebar.toggle', (event) => changeSidebar(event), [sidebarOpen]);

  return (
    <AuthContext>
      <Grommet full theme={theme} themeMode={colorScheme ? 'light' : 'dark'} id="pageContainer">
        <TourRoot>
          <Helmet>
            <meta charSet="utf-8" />
            <title>{config.siteTitle}</title>
          </Helmet>
          <Box fill>
            <Authenticated>
              <PrivateRoute>{loading ? <AppHeaderSkeleton path="app/assistant/*" /> : <AppHeader path="app/assistant/*" />}</PrivateRoute>
            </Authenticated>
            <Box flex direction="row" fill>
              {!isSSR && (
                <Suspense fallback={<Splash />}>
                  <Authenticated>
                    <PrivateRoute>
                      <SideBar path="app/admin/*" />
                    </PrivateRoute>
                  </Authenticated>
                </Suspense>
              )}
              <Box fill="horizontal">
                {!isSSR && (
                  <Suspense fallback={<Splash />}>
                    <Authenticated>
                      <PrivateRoute>
                        <Profile path="app/assistant/profile" />
                        <Board path="app/assistant/board" />
                        <GridBoard path="app/assistant/grid-board" />
                        <MyCards path="app/assistant/mycards" />
                        <MyConnectors path="app/assistant/myconnectors" />
                        <Page1 path="app/admin/page1" />
                        <Page2 path="app/admin/page2" />
                        <ChangePassword path="app/assistant/changepassword" />
                        <GoogleCseDemo path="app/google-cse-demo" />
                      </PrivateRoute>
                    </Authenticated>
                  </Suspense>
                )}
              </Box>
              {sidebarOpen && (
                <Layer animate modal full="vertical" position="right" responsive onClickOutside={() => dispatch({ type: 'sidebar.toggle', open: false })}>
                  <AppSidebar {...sidebarConfig}></AppSidebar>
                </Layer>
              )}
            </Box>
            <AppFooter></AppFooter>
          </Box>
        </TourRoot>
      </Grommet>
      <Authenticated>
        <div path="app/callback" />
      </Authenticated>
    </AuthContext>
  );
};

export default App;
